import React, { Component } from "react"
import handleViewport from "react-in-viewport"
import anime from "animejs/lib/anime.es.js"
import styles from "./team.module.scss"
import { CSSTransition } from "react-transition-group"
import Image from "gatsby-image"
import Dropdown from 'react-dropdown'
// icons
import ChevronDown from "assets/icons/chevron-down.svg"

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transition: null,
      filterValue: "null",
      filterLabel: "Iedereen",
      showImage: null,
      selected: null
    }
    this._onSelect = this._onSelect.bind(this)
  }

  _onSelect (option) {
    this.setState({selected: option.label})
  }

  componentDidMount() {
    this.randomImage()
    this.setState({
      filterValue: null,
    })
    const transition = anime
      .timeline({
        easing: "easeOutCubic",
        duration: 800,
        autoplay: false,
      })
      .add({
        targets: this.refs.component.querySelector(`.${styles.filter}`),
        duration: 400,
        translateY: ["80px", "0px"],
        opacity: [0, 1],
        easing: "easeInOutCirc(1, .5)",
      })
      .add({
        targets: this.refs.component.querySelector(`.${styles.items}`),
        translateY: ["80px", "0px"],
        opacity: [0, 1],
        duration: 400,
        easing: "easeInOutExpo",
      })

    this.setState({
      transition,
    })
  }

  randomImage = () => {
    setInterval(() => {
      this.props.items.filter(item => item.imageTwo)
      let items = this.props.items.filter(item => {
        return item.imageTwo.src
      })
      let rand = items[Math.floor(Math.random() * items.length)]
      this.setState({
        showImage: rand.emailaddress,
      })
      setTimeout(() => {
        this.setState({
          showImage: null,
        })
      }, 400)
    }, 1000)
  }

  filter = event => {

    let value = event.value
    let label = event.label
    if (value === "") {
      value = null
    }
    this.setState({
      filterValue: "hidden",
      filterLabel: label,
    })
    setTimeout(() => {
      this.setState({
        filterValue: value,
      })
    }, 200)
  }
  render() {
    const { filterValue, filterLabel } = this.state
    const { items } = this.props
    const options = [
      { value: "", label: 'Iedereen', className: styles.dropdownoption },
      { value: "k", label: "Keytoe K", className: styles.dropdownoption },
      { value: "y", label: "Keytoe Y", className: styles.dropdownoption },
      { value: "t", label: "Toscani", className: styles.dropdownoption},
      { value: "s", label: "Schwung", className: styles.dropdownoption }
    ]
    const defaultOption = options[0]
    return (
      <div className={styles.team} ref="component">
        <div className="wrapper">
          <div className={styles.filter}>
            <span>Ik wil </span>
            <div className={styles.select}>
              <span className={styles.line}></span>
              {/* <label>
                {filterLabel} <ChevronDown className={styles.icon} />
              </label> */}
              <Dropdown 
                arrowClosed={<ChevronDown className={styles.arrowdropdown} />} 
                arrowOpen={<ChevronDown className={styles.arrowdropdownIsOpen} />} 
                className={styles.dropdown} 
                controlClassName={styles.controldropdown} 
                menuClassName={styles.menudropdown} 
                options={options} 
                onChange={(e) => {this._onSelect(e); this.filter(e)}}
                value={this.state.selected ? (this.state.selected) : (defaultOption)} 
                placeholder=""
              />
              {/* <select onChange={this.filter}>
                <option value="">iedereen</option>
                <option value="k">Keytoe K</option>
                <option value="y">Keytoe Y</option>
                <option value="t">Keytoe T</option>
                <option value="s">Schwung</option>
              </select> */}
            </div>
            <span> zien</span>
          </div>
          <div className={styles.items}>
            {items &&
              items.map((item, i) => {
                return (
                  <CSSTransition
                    timeout={{
                      enter: 300,
                      exit: 100,
                    }}
                    in={filterValue === item.team || filterValue === null}
                    classNames={{
                      enterDone: styles.itemEnter,
                      exitDone: styles.itemExit,
                    }}
                    mountOnEnter
                    unmountOnExit
                    key={i}
                  >
                    <div className={styles.item}>
                      {item.image.src && (
                        <Image
                          fluid={item.image.src.localFile.childImageSharp.fluid}
                          className={styles.image}
                          alt={item.image.alt}
                          title={item.image.title}
                        />
                      )}
                      {item.imageTwo.src &&
                        this.state.showImage === item.emailaddress && (
                          <Image
                            fluid={
                              item.imageTwo.src.localFile.childImageSharp.fluid
                            }
                            className={styles.imageTwo}
                            alt={item.image.alt}
                            title={item.image.title}
                          />
                        )}
                      <span
                        className={styles.name}
                        dangerouslySetInnerHTML={{ __html: item.firstName }}
                      />
                      <span
                        className={styles.sub}
                        dangerouslySetInnerHTML={{ __html: item.jobTitle }}
                      />
                      <span className={styles.contact}>
                        <a
                          href={`mailto:${item.emailaddress}`}
                          dangerouslySetInnerHTML={{
                            __html: item.emailaddress,
                          }}
                        />
                        {item.phonenumber && (
                          <a
                            href={`tel:${item.phonenumber}`}
                            dangerouslySetInnerHTML={{
                              __html: item.phonenumber,
                            }}
                          />
                        )}
                      </span>
                    </div>
                  </CSSTransition>
                )
              })}
          </div>
        </div>
      </div>
    )
  }
}
const TeamTransition = handleViewport(Team, {
  threshold: 0.000000001,
})
export default TeamTransition
