import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import SEO from "components/SEO"
import ContentBlock from "components/ContentBlock"
import PageHero from "components/PageHero"
import Meetup from "components/Meetup"
import Team from "components/Team"

const WhoTemplate = props => {
  const { hero, content, team, cta, seo } = props.data.wordpressWho

  return (
    <Layout location={props.location} default navigation>
      <SEO seo={seo} />
      <PageHero details={hero} />
      <ContentBlock
        large
        title={content.title}
        content={content.content}
        link={content.link}
      />
      <Team items={team} />
      <Meetup type="noBackground" details={cta} />
    </Layout>
  )
}

export default WhoTemplate

export const whoQuery = graphql`
  query WhoQuery($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    wordpressWho(id: { eq: $id }) {
      id
      slug
      team {
        emailaddress
        firstName
        jobTitle
        image {
          alt
          title
          src {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        imageTwo {
          src {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600, maxHeight: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        lastName
        phonenumber
        team
      }
      content {
        content
        link {
          label
          link
        }
        title
      }
      cta {
        buttons {
          link
          text
        }
        title
        titleMultiple
      }
      hero {
        title {
          text
        }
        image {
          src {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                  srcSetBreakpoints: [400, 1024, 1280, 1921]
                  maxWidth: 1921
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      seo {
        title
        siteName
        description
        image {
          src {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 853, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
